<template>
  <div class="mall">
    <div class="list">
      <van-grid :border="false" :column-num="3">
        <van-grid-item
          v-for="(item,index) in CateList"
          :key="'n'+index"
          class="category-item"
          @click="changecate(item)"
        >
          <p class="image">
            <van-image :src="item.icon" />
          </p>
          <p class="text">{{item.category}}</p>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
export default {
  name: "MainIndex",
  data() {
    return {
      CateList: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function() {
      var that = this;
      var params = {};
      that.$api.getMallcate(params).then(res => {
        that.CateList = res.data;
      });
    },
    changecate(item) {
      this.cateid = item.id;
      this.$router.push({
        path: "/Mall",
        query: { active: 5, categoryid: item.id }
      });
    }
  }
};
</script>
<style lang="less" scoped="">
.mall {
  background: #fff;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}
.title {
  margin-top: 20px;
}
.title img {
  width: 50%;
}

/deep/.van-grid-item .van-grid-item__content {
  padding: 1rem;
  background: #f2f2f2;
}
.category-item {
  border-radius: 0.5rem;
  padding: 1rem;
}

.category-item .image {
  margin: 0;
  padding: 0;
}
.category-item .text {
  margin: 0;
  padding: 0;
  font-size: 2.4rem;
}
</style>